@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.templates {
  font-family: Montserrat, sans-serif;
  &__appetito {
    &__block {
      width: 100vw;
      max-height: 100vh;
      height: 100vh;
      overflow: hidden;
      background: linear-gradient(to right, #E30713 50%, transparent 50%);
      &__head {
        width: 100%;
        display: flex;
        justify-content: space-around;
        &__title {
          padding: 2.8846153846153846vh 2.5vw;
          color: #089970;
          text-align: center;
          font-family: Montserrat, sans-serif;
          font-size: 4.583333333333333vw;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          display: flex;
          align-items: center;
          overflow: hidden;
          width: 50%;
          & span {
            overflow:hidden;
            display:inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          & img {
            width: 3.515625vw;
            margin-left: 0.78125vw;
          }
          &:first-child {
            color: #fff;
            background-color: #E30713;
          }
        }
      }
      &__main {
        display: flex;
        height: 100%;
        &__numbers {
          width: 100%;
          height: calc(100vh - 8.331730769230769vh - 6.640625vw);
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          margin: .5vw 1.771875vw 0;
          &__number {
            color: #fff;
            font-family: Montserrat, sans-serif;
            font-size: 4.296875vw;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            width: calc(21.875vw + 0.78125vw);
            margin-right: 0.9375vw;
            &-done {
              color: #518C70;
            }
          }
        }
        &__line {
          height: 100%;
          width: 0.3125vw;
          background: #E1DBDB;
        }
      }
    }
    &__more {
      color: white !important;
    }
  }
}

.hide_block {
  opacity: 0;
}

