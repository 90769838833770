.cabinet-templates {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  background: #F8F8F8;
  overflow-y: auto;
  height: calc(min(545px, 42.578125vw));
  &__body {
    padding: calc(min(20px, 1.5625vw)) calc(min(35px, 2.734375vw)) calc(min(20px, 1.5625vw)) calc(min(34px, 2.65625vw));
    text-align: left;
    &__title {
      color: #585858;
      font-family: Inter, sans-serif;
      font-size: calc(min(16px, 1.25vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-bottom: calc(min(5px, 0.390625vw));
      border-bottom: calc(min(1px, 0.078125vw)) solid rgba(0, 0, 0, 0.10);
      width: calc(min(567px, 44.296875vw));
      &-next {
        margin-top: calc(min(41px, 3.2031249999999996vw));
      }
    }
    &__blocks {
      margin-top: calc(min(21px, 1.640625vw));
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: calc(min(65px, 5.078125vw));
      &__template  {
        position: relative;
        cursor: pointer;
        & img {
          width: calc(calc(min(209px, 16.328125vw)));
        }
      }
      &__block {
        padding: calc(min(10px, 0.78125vw)) calc(min(12px, 0.9375vw));
        display: flex;
        width: calc(calc(min(209px, 16.328125vw)) - calc(min(30px, 2.34375vw)));
        background:  white;
        box-shadow: 0px calc(min(2px, 0.15625vw)) calc(min(2px, 0.15625vw)) 0px rgba(0, 0, 0, 0.10);
        position: relative;
        &__active {
          position: absolute;
          bottom: 0;
          left: 41%;
          transform: translate(-50%, calc(100% + calc(min(5px, 0.390625vw))));
          display: flex;
          align-items: center;
          color: #518C70;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: calc(min(12px, 0.9375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          & img {
            margin-left: calc(min(4px, 0.3125vw));
          }
        }
        &-two {
          padding: 0;
          width: calc(min(203px, 15.859375vw));
          .cabinet-templates__body__blocks__block {
            &__line {
              margin: 0;
              height: calc(calc(min(20px, 1.5625vw)) + calc(min(120px, 9.375vw)));
            }
            &__item {
              &__title {
                background: #6B0B18;
                border-radius: 0;
                justify-content: flex-start;
                padding: 0 calc(min(12px, 0.9375vw));
                width: calc(100% - calc(min(24px, 1.875vw)));
                &-done {
                  background: #183E30;
                  margin-left: calc(min(-1px, -0.078125vw));
                  width: calc(100% - calc(min(23px, 1.7968749999999998vw)));
                }
              }
              &__numbers {
                padding: 0 calc(min(12px, 0.9375vw));
                &__number {
                  background: none;
                  color: #6B0B18;
                  &-done {
                    color: #183E30;
                  }
                }
              }
            }
          }
        }
        &-three {
          padding: 0;
          width: calc(min(203px, 15.859375vw));
          .cabinet-templates__body__blocks__block {
            &__line {
              margin: 0;
              height: calc(calc(min(20px, 1.5625vw)) + calc(min(120px, 9.375vw)));
            }
            &__item {
              &__title {
                background: #000;
                border-radius: 0;
                padding: 0 calc(min(12px, 0.9375vw));
                width: calc(100% - calc(min(24px, 1.875vw)));
                & img {
                  width: calc(min(13px, 1.015625vw));
                  margin-left: calc(min(5px, 0.390625vw));
                }
                &-done {
                  background: #000;
                  margin-left: calc(min(-1px, -0.078125vw));
                  width: calc(100% - calc(min(23px, 1.7968749999999998vw)));
                }
              }
              &__numbers {
                padding: 0 calc(min(12px, 0.9375vw));
                &__number {
                  background: none;
                  color: #757575;
                  &-done {
                    color: #518C70;
                  }
                }
              }
            }
          }
        }
        &__item {
          width: 100%;
          cursor: pointer;
          &__title {
            border-radius: calc(min(2px, 0.15625vw));
            background: #422757;
            box-shadow: 0px calc(min(2px, 0.15625vw)) calc(min(2px, 0.15625vw)) 0px rgba(0, 0, 0, 0.25);
            color: #FFF;
            text-align: center;
            font-family: Inter, sans-serif;
            font-size: calc(min(8px, 0.625vw));
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            width: 100%;
            height: calc(min(20px, 1.5625vw));
            display: flex;
            align-items: center;
            justify-content: center;
            &-done {
              background-color: #518C70;
            }
            & img {
              width: calc(min(15px, 1.171875vw));
              margin-left: calc(min(5px, 0.390625vw));
            }
          }
          &__numbers {
            margin-top: calc(min(10px, 0.78125vw));
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: calc(min(5px, 0.390625vw));
            &__number {
              color: #422757;
              font-family: Inter, sans-serif;
              font-size: calc(min(10px, 0.78125vw));
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              border-radius: calc(min(2px, 0.15625vw));
              background: #D3D3D3;
              display: flex;
              align-items: center;
              justify-content: center;
              &-done {
                background-color: #DAEEE4;
                color: #518C70;
              }
            }
          }
        }
        &__line {
          height: calc(min(120px, 9.375vw));
          width: calc(min(1px, 0.078125vw));
          background-color: #E1DBDB;;
          margin: 0 calc(min(15px, 1.171875vw));
        }
      }
    }
  }
  &__footer {
    padding: calc(min(22px, 1.7187500000000002vw)) calc(min(32px, 2.5vw));
    display: flex;
    justify-content: flex-end;
  }
}
